/* eslint-disable camelcase */
import * as Domain from '@/models/Domain';
import * as ModuleDomain from '@/models/ModuleDomain';
import * as RecurrenceHelper from '../helpers/RecurrenceHelper';
import { UUID } from 'uuidjs';

export abstract class StudioModuleMutation extends ModuleDomain.MutationBase implements ModuleDomain.IMutationAltersData {
  public metadata_upserts: any[] = [];
  public metadata_deletes: any[] = [];
  public metadata_potentiallyNothingToPersist: boolean = false;
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerMutationRestore
export class RestoreMutation extends StudioModuleMutation {
  public studios: Domain.Studio[] = [];
  public toDos: Domain.ToDo[] = [];
  public removedStudioIds: string[] = [];
  public removedToDoIds: string[] = [];
  public out_error: boolean = false;

  constructor(studios: Domain.Studio[], toDos: Domain.ToDo[], removedStudioIds: string[], removedToDoIds: string[]) {
    super();
    this.metadata_potentiallyNothingToPersist = true;

    this.studios = studios;
    this.toDos = toDos;
    this.removedStudioIds = removedStudioIds;
    this.removedToDoIds = removedToDoIds;
  }

  public static MutationName: string = 'studio/restore';
}

@ModuleDomain.registerMutationAltersData
export class ResetMutation extends StudioModuleMutation {
  constructor() {
    super();
    this.metadata_potentiallyNothingToPersist = true;
  }

  public static MutationName: string = 'studio/reset';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class CreateTeamStudioMutation extends StudioModuleMutation {
  public studioId: string;
  public userId: string;
  public tags: Domain.Tag[];
  public title: string;
  public purpose: string;
  public color: string;
  public isSolo: boolean;

  constructor(studioId: string, userId: string, tags: Domain.Tag[], title: string, purpose: string, color: string, isSolo: boolean) {
    super();
    this.studioId = studioId;
    this.userId = userId;
    this.tags = tags;
    this.title = title;
    this.purpose = purpose;
    this.color = color;
    this.isSolo = isSolo;
  }

  public static MutationName: string = 'studio/createTeamStudio';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class DeleteStudioMutation extends StudioModuleMutation {
  public studioId: string;
  public userId: string;

  constructor(studioId: string, userId: string) {
    super();
    this.studioId = studioId;
    this.userId = userId;
  }

  public static MutationName: string = 'studio/deleteStudio';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class DeleteToDoMutation extends StudioModuleMutation {
  public studioId: string;
  public toDoId: string;
  public userId: string;

  constructor(studioId: string, toDoId: string, userId: string) {
    super();
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.userId = userId;
  }

  public static MutationName: string = 'studio/deleteToDo';
}


@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class UpdateToDoTemplateMutation extends StudioModuleMutation {
  public userId: string;
  public studioId: string;
  public toDoTemplateId: string;
  public name: string;
  public items: Domain.ToDoTemplateItem[] = [];

  constructor(userId: string, studioId: string, toDoTemplateId: string, name: string, items: Domain.ToDoTemplateItem[] = []) {
    super();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoTemplateId = toDoTemplateId;
    this.name = name;
    this.items = items;
  }

  public static MutationName: string = 'studio/updateToDoTemplate';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class OpenStudioMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public reason: string;

  constructor(userId: string, studioId: string, reason: string) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.reason = reason;
  }

  public static MutationName: string = 'studio/openStudio';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class CloseStudioMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public reason: string;

  constructor(userId: string, studioId: string, reason: string)  {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.reason = reason;
  }

  public static MutationName: string = 'studio/closeStudio';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
export class ChangeStudioTitleMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public newTitle: string;

  constructor(userId: string, studioId: string, newTitle: string) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.newTitle = newTitle;
  }

  public static MutationName: string = 'studio/changeStudioTitle';
}


@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
export class ChangeStudioPurposeMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public newPurpose: string;

  constructor(userId: string, studioId: string, newPurpose: string) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.newPurpose = newPurpose;
  }

  public static MutationName: string = 'studio/changeStudioPurpose';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
export class ChangeStudioSoloStatusMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public isSolo: boolean;

  constructor(userId: string, studioId: string, isSolo: boolean) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.isSolo = isSolo;
  }

  public static MutationName: string = 'studio/changeStudioSoloStatus';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class CreateStudioInvitationMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public invitationId: string;

  constructor(userId: string, studioId: string, invitationId: string) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.invitationId = invitationId;
  }

  public static MutationName: string = 'studio/createStudioInvitation';
}

@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class ProcessStudioInvitationMutation extends StudioModuleMutation {
  public userId: string;
  public invitationId: string;

  constructor(userId: string, invitationId: string) {
    super();
    this.userId = userId;
    this.invitationId = invitationId;
  }

  public static MutationName: string = 'studio/processStudioInvitation';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class InviteStudioTeammateMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public inviteeUserId: string;

  constructor(userId: string, studioId: string, inviteeUserId: string) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.inviteeUserId = inviteeUserId;
  }

  public static MutationName: string = 'studio/inviteStudioTeammate';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class LeaveStudioMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public reason: string;

  constructor(userId: string, studioId: string, reason: string = '') {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.reason = reason;
  }

  public static MutationName: string = 'studio/leaveStudio';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class RemoveStudioTeammateMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public teammateUserId: string;
  public reason: string;

  constructor(userId: string, studioId: string, teammateUserId: string, reason: string = '') {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.teammateUserId = teammateUserId;
    this.reason = reason;
  }

  public static MutationName: string = 'studio/removeStudioTeammate';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class CreateTaskMutation extends StudioModuleMutation {
  public toDoId: string;
  public userId: string;
  public studioId: string;
  public tags: Domain.Tag[];
  public title: string;
  public description: string;
  public ownerUserId: string | null;
  public recurrence: string;
  public effort: Domain.EnumEffort;
  public urgency: Domain.EnumUrgency;
  public importance: Domain.EnumImportance;
  public dueInstant: Date | null;
  public startedInstant: Date | null;

  constructor(toDoId: string, userId: string, studioId: string, tags: Domain.Tag[], title: string, description: string = '', ownerUserId: string | null = null, recurrence: string = RecurrenceHelper.noRecurrence, effort: Domain.EnumEffort = Domain.EnumEffort.xSmall, urgency: Domain.EnumUrgency = Domain.EnumUrgency.medium, importance: Domain.EnumImportance = Domain.EnumImportance.medium, dueInstant: Date | null = null, startedInstant: Date | null = null) {
    super();
    this.toDoId = toDoId;
    this.userId = userId;
    this.studioId = studioId;
    this.tags = tags;
    this.title = title;
    this.description = description;
    this.ownerUserId = ownerUserId;
    this.effort = effort;
    this.urgency = urgency;
    this.importance = importance;
    this.dueInstant = dueInstant;
    this.recurrence = recurrence;
    this.startedInstant = startedInstant;
  }

  public static MutationName: string = 'studio/createTask';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class CancelToDoMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public messageBody: string;

  constructor(userId: string, studioId: string, toDoId: string, messageBody: string = '') {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.messageBody = messageBody;
  }

  public static MutationName: string = 'studio/cancelToDo';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class RestoreToDoMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public messageBody: string;

  constructor(userId: string, studioId: string, toDoId: string, messageBody: string = '') {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.messageBody = messageBody;
  }

  public static MutationName: string = 'studio/restoreToDo';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
export class ChangeToDoOwnerMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public newOwnerUserId: string;

  constructor(userId: string, studioId: string, toDoId: string, newOwnerUserId: string) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.newOwnerUserId = newOwnerUserId;
  }

  public static MutationName: string = 'studio/changeToDoOwner';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
export class ChangeToDoTitleMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public newTitle: string;

  constructor(userId: string, studioId: string, toDoId: string, newTitle: string) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.newTitle = newTitle;
  }

  public static MutationName: string = 'studio/changeToDoTitle';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
export class ChangeToDoDescriptionMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public newDescription: string;

  constructor(userId: string, studioId: string, toDoId: string, newDescription: string) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.newDescription = newDescription;
  }

  public static MutationName: string = 'studio/changeToDoDescription';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
export class ChangeToDoUrgencyMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public newUrgency: Domain.EnumUrgency;

  constructor(userId: string, studioId: string, toDoId: string, newUrgency: Domain.EnumUrgency) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.newUrgency = newUrgency;
  }

  public static MutationName: string = 'studio/changeToDoUrgency';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
export class ChangeToDoImportanceMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public newImportance: Domain.EnumImportance;

  constructor(userId: string, studioId: string, toDoId: string, newImportance: Domain.EnumImportance) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.newImportance = newImportance;
  }

  public static MutationName: string = 'studio/changeToDoImportance';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
export class ChangeToDoEffortMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public newEffort: Domain.EnumEffort;

  constructor(userId: string, studioId: string, toDoId: string, newEffort: Domain.EnumEffort) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.newEffort = newEffort;
  }

  public static MutationName: string = 'studio/changeToDoEffort';
}


@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
export class ChangeToDoDueInstantMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public newDueInstant: Date;

  constructor(userId: string, studioId: string, toDoId: string, newDueInstant: Date) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.newDueInstant = newDueInstant;
  }

  public static MutationName: string = 'studio/changeToDoDueInstant';
}


@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
export class ChangeToDoRecurrenceMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public newRecurrence: string;

  constructor(userId: string, studioId: string, toDoId: string, newRecurrence: string) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.newRecurrence = newRecurrence;
  }

  public static MutationName: string = 'studio/changeToDoRecurrence';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class PostponeMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public messageBody: string;
  public messageTargetUserIds: string[];
  public postponementInstant: Date;

  constructor(userId: string, studioId: string, toDoId: string, messageBody: string, messageTargetUserIds: string[], postponementInstant: Date) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.messageBody = messageBody;
    this.messageTargetUserIds = messageTargetUserIds;
    this.postponementInstant = postponementInstant;
  }

  public static MutationName: string = 'studio/postpone';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class CommitMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public messageBody: string;
  public messageTargetUserIds: string[];
  public commitmentInstant: Date;
  public progressId: string;
  public progressInstant: Date | null;
  public progressSteps: Domain.ProgressStep[] = [];

  constructor(userId: string, studioId: string, toDoId: string, messageBody: string, messageTargetUserIds: string[], commitmentInstant: Date, progressInstant: Date | null = null, progressSteps: Domain.ProgressStep[] = []) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.messageBody = messageBody;
    this.messageTargetUserIds = messageTargetUserIds;
    this.commitmentInstant = commitmentInstant;
    this.progressId = UUID.genV1().toString();
    this.progressInstant = progressInstant;
    this.progressSteps = progressSteps;
  }

  public static MutationName: string = 'studio/commit';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class CompleteMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public messageBody: string;
  public messageTargetUserIds: string[];
  public progressId: string;
  public progressInstant: Date | null;
  public progressSteps: Domain.ProgressStep[] = [];

  constructor(userId: string, studioId: string, toDoId: string, messageBody: string, messageTargetUserIds: string[], progressInstant: Date | null, progressSteps: Domain.ProgressStep[] = []) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.messageBody = messageBody;
    this.messageTargetUserIds = messageTargetUserIds;
    this.progressId = UUID.genV1().toString();
    this.progressInstant = progressInstant;
    this.progressSteps = progressSteps;
  }

  public static MutationName: string = 'studio/complete';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class DelegateMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public messageBody: string;
  public messageTargetUserIds: string[];

  constructor(userId: string, studioId: string, toDoId: string, messageBody: string, messageTargetUserIds: string[]) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.messageBody = messageBody;
    this.messageTargetUserIds = messageTargetUserIds;
  }

  public static MutationName: string = 'studio/delegate';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class FollowMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public messageBody: string;
  public messageTargetUserIds: string[];

  constructor(userId: string, studioId: string, toDoId: string, messageBody: string, messageTargetUserIds: string[]) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.messageBody = messageBody;
    this.messageTargetUserIds = messageTargetUserIds;
  }

  public static MutationName: string = 'studio/follow';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class WithdrawMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public messageBody: string;
  public messageTargetUserIds: string[];

  constructor(userId: string, studioId: string, toDoId: string, messageBody: string, messageTargetUserIds: string[]) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.messageBody = messageBody;
    this.messageTargetUserIds = messageTargetUserIds;
  }

  public static MutationName: string = 'studio/withdraw';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
export class SendMessageMutation extends StudioModuleMutation {
  public actionId: string;
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public messageBody: string;
  public messageTargetUserIds: string[];

  constructor(userId: string, studioId: string, toDoId: string, messageBody: string, messageTargetUserIds: string[]) {
    super();
    this.actionId = UUID.genV1().toString();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.messageBody = messageBody;
    this.messageTargetUserIds = messageTargetUserIds;
  }

  public static MutationName: string = 'studio/sendMessage';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
export class AcknowledgeMessageMutation extends StudioModuleMutation {
  public userId: string;
  public studioId: string;
  public toDoId: string;

  constructor(userId: string, studioId: string, toDoId: string) {
    super();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
  }

  public static MutationName: string = 'studio/acknowledgeMessage';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class StarMutation extends StudioModuleMutation {
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public on: boolean;

  constructor(userId: string, studioId: string, toDoId: string, on: boolean) {
    super();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.on = on;
  }

  public static MutationName: string = 'studio/star';
}

@ModuleDomain.registerMutationAltersData
export class ForceCompute extends StudioModuleMutation {
  public studios: Domain.Studio[];
  public toDos: Domain.ToDo[];
  public userId: string;
  constructor(studios: Domain.Studio[], toDos: Domain.ToDo[], userId: string) {
    super();
    this.metadata_potentiallyNothingToPersist = true;

    this.studios = studios;
    this.toDos = toDos;
    this.userId = userId;
  }
  public static MutationName: string = 'studio/forceCompute';
}
