/* eslint-disable no-unused-vars */
// tslint:disable:max-classes-per-file
// tslint:disable:max-line-length
import * as StringHelper from '../helpers/StringHelper';
import * as DateHelper from '../helpers/DateHelper';
import * as RecurrenceHelper from '../helpers/RecurrenceHelper';
import { UUID } from 'uuidjs';
import TipsDataArray from './Tips.json';

export const DomainTypes: Map<string, any> = new Map<string, any>();

function registerDomainType(name: string) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  // eslint-disable-next-line @typescript-eslint/ban-types
  return function registerDomainType(constructor: Function): void {
    DomainTypes.set(name, constructor);
  };
}

export enum EnumPlatform {
  mac = 'mac',
  windows = 'windows',
  iphone = 'iphone',
  ipad = 'ipad',
  android = 'android',
  chromebook = 'chromebook',
  other = 'other',
}

export enum EnumBrowser {
  edge = 'edge',
  chrome = 'chrome',
  safari = 'safari',
  other = 'other',
}

export enum EnumBrowserContainer {
  browser = 'browser',
  sidePanel = 'sidePanel',
  app = 'app',
}

@registerDomainType('Organization')
export class Organization {
  public type: string;
  public id: string;
  public creationInstant: Date;
  public creatorUserId: string;
  public name: string;
  public featureFlags: Map<string, string>;

  constructor(id: string, creationInstant: Date, creatorUserId: string, name: string) {
    this.type = 'Organization';
    this.id = id;
    this.creationInstant = creationInstant;
    this.creatorUserId = creatorUserId;
    this.name = name;
    this.featureFlags = new Map<string, string>();
  }
}

@registerDomainType('SubscriptionDetails')
export class SubscriptionDetails {
  public type: string;
  public isPro: boolean;

  constructor(isPro: boolean) {
    this.type = 'SubscriptionDetails';
    this.isPro = isPro;
  }
}

export enum EnumLocale {
  en = 'en',
  fr = 'fr',
  es = 'es',
}

export enum EnumTagVisibility {
  system = 'system',
  customHidden = 'customHidden',
  customVisible = 'customVisible',
  customEditable = 'customEditable',
}

@registerDomainType('Tag')
export class Tag {
  public type: string;
  public id: string;
  public kind: string;
  public value: string;
  public visibility: EnumTagVisibility;

  constructor(id: string, value: string, kind: string = '', visibility: EnumTagVisibility) {
    this.type = 'Tag';
    this.id = id;
    this.kind = kind;
    this.value = value;

    this.visibility = visibility;

    if (kind === '') { // verify if kind is not between parentheses within value
      const regex = /\((.*?)\)/; // match substring between parentheses
      const match = value.match(regex);
      
      if (match && match.length > 0) {
        this.kind = match.length > 1 ? match[1].trim() : ''; // match[1]  refers to the capture string inside parentheses
        this.value = value.replace(match[0], '').trim(); // match[0] refers to the entire match
      }
    }
  }
}

export enum EnumUserStatus {
  active = 'active',
  awaitingApprouval = 'awaitingApprouval',
  inactive = 'inactive',
  blocked = 'blocked'
}

@registerDomainType('User')
export class User {
  public type: string;
  public id: string;
  public etag: string;
  public creationInstant: Date;
  public status: EnumUserStatus;
  public email: string | null;
  public emails: string[];
  public nickname: string;
  public firstName: string;
  public lastName: string;
  public get initials(): string { return (this.firstName[0] + this.lastName[0]) || '??'; }
  public get fullName(): string { return (this.firstName + ' ' + this.lastName) || ''; }
  public locale: EnumLocale;
  public tags: Map<string, Tag>;
  public featureFlags: Map<string, string>;
  public color: string;
  public pictureUrl: string;
  public pictureUrls: string[];
  public lastSeenTimeZone: string;
  public lastSeenInstant: Date;
  public isProfileConfirmed: boolean;
  public subscriptionDetails: SubscriptionDetails;

  constructor(id: string, creationInstant: Date, email: string | null, emails: string[], nickname: string, firstName: string, lastName: string, locale: EnumLocale, pictureUrl: string, pictureUrls: string[]) {
    this.type = 'User';
    this.id = id;
    this.creationInstant = creationInstant;
    this.status = EnumUserStatus.active;
    this.email = email;
    this.emails = emails;
    this.nickname = nickname;
    this.firstName = firstName;
    this.lastName = lastName;
    this.locale = locale;
    this.tags = new Map<string, Tag>();
    this.featureFlags = new Map<string, string>();
    this.color = StringHelper.toPastelColor(this.fullName);
    this.pictureUrl = pictureUrl;
    this.pictureUrls = pictureUrls;
    this.lastSeenTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.lastSeenInstant = new Date();
    this.isProfileConfirmed = false;
    this.subscriptionDetails = new SubscriptionDetails(false);
  }
}

@registerDomainType('PublicUser')
export class PublicUser {
  public type: string;
  public id: string;
  public etag: string;
  public nickname: string;
  public firstName: string;
  public lastName: string;
  public get initials(): string { return (this.firstName[0] + this.lastName[0]) || '??'; }
  public get fullName(): string { return (this.firstName + ' ' + this.lastName) || ''; }
  public locale: EnumLocale;
  public tags: Map<string, Tag>;
  public color: string;
  public pictureUrl: string;
  public lastSeenTimeZone: string;
  public lastSeenInstant: Date;

  constructor(id: string, nickname: string, firstName: string, lastName: string, locale: EnumLocale, pictureUrl: string) {
    this.type = 'PublicUser';
    this.id = id;
    this.nickname = nickname;
    this.firstName = firstName;
    this.lastName = lastName;
    this.locale = locale;
    this.tags = new Map<string, Tag>();
    this.color = StringHelper.toPastelColor(this.fullName);
    this.pictureUrl = pictureUrl;
    this.lastSeenTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.lastSeenInstant = new Date();
  }
}

export abstract class Notification {
  public type: string;
  public id: string;
  public etag: string;
  public userId: string;
  public message: string;

  constructor(id: string, userId: string, message: string = '') {
    this.type = 'Notification';
    this.id = id;
    this.userId = userId;
    this.message = message;
  }
}

export abstract class AcknowledgementNotification extends Notification {
  public isAcknowledged: boolean;

  constructor(id: string, userId: string, message: string = '') {
    super(id, userId, message);
    this.type = 'AcknowledgementNotification';
    this.isAcknowledged = false;
  }
}

export abstract class SelectionNotification extends Notification {
  public selectedSelection: string | null;
  public possibleSelections: string[];

  constructor(id: string, userId: string, message: string = '', possibleSelections: string[] = []) {
    super(id, userId, message);
    this.type = 'SelectionNotification';
    this.selectedSelection = null;
    this.possibleSelections = possibleSelections;
  }
}

@registerDomainType('SystemMessageNotification')
export class SystemMessageNotification extends AcknowledgementNotification {
  constructor(id: string, userId: string, message: string = '') {
    super(id, userId, message);
    this.type = 'SystemMessageNotification';
  }
}

@registerDomainType('SystemSelectionNotification')
export class SystemSelectionNotification extends SelectionNotification {
  constructor(id: string, userId: string, message: string = '', possibleSelections: string[] = []) {
    super(id, userId, message, possibleSelections);
    this.type = 'SystemSelectionNotification';
  }
}

export enum EnumPaddleSubscriptionEvent {
  subscriptionActivated = 'subscriptionActivated',
  subscriptionPastDue = 'subscriptionPastDue',
  subscriptionCanceled = 'subscriptionCanceled',
  subscriptionUpdated = 'subscriptionUpdated',
  subscriptionTrial = 'subscriptionTrial',
  subscriptionPaused = 'subscriptionPaused',
  subscriptionResumed = 'subscriptionResumed',
  proLicenseActivated = 'proLicenseActivated',
  proLicenseDeactivated = 'proLicenseDeactivated'
}

@registerDomainType('PaddleSubscriptionChangeNotification')
export class PaddleSubscriptionChangeNotification extends AcknowledgementNotification {
  public events: EnumPaddleSubscriptionEvent[] = [];
  public subscriptionId: string;

  constructor(id: string, userId: string, message: string = '', subscriptionId: string, events: EnumPaddleSubscriptionEvent[]) {
    super(id, userId, message);
    this.type = 'PaddleSubscriptionChangeNotification';
    this.subscriptionId = subscriptionId;
    this.events = events;
  }
}

@registerDomainType('StudioRemovalNotification')
export class StudioRemovalNotification extends AcknowledgementNotification {
  public senderUserId: string;
  public senderFirstName: string;
  public senderNickname: string;
  public senderInitials: string;
  public senderColor: string;
  public senderPictureUrl: string;
  public studioId: string;
  public studioTitle: string;
  public studioColor: string;

  constructor(id: string, userId: string, message: string, senderUserId: string, senderFirstName: string, senderNickname: string, senderInitials: string, senderColor: string, senderPictureUrl: string, studioId: string, studioTitle: string, studioColor: string) {
    super(id, userId, message);
    this.type = 'StudioRemovalNotification';
    this.senderUserId = senderUserId;
    this.senderFirstName = senderFirstName;
    this.senderNickname = senderNickname;
    this.senderInitials = senderInitials;
    this.senderColor = senderColor;
    this.senderPictureUrl = senderPictureUrl;
    this.studioId = studioId;
    this.studioTitle = studioTitle;
    this.studioColor = studioColor;
  }
}

@registerDomainType('StudioDeletionNotification')
export class StudioDeletionNotification extends AcknowledgementNotification {
  public senderUserId: string;
  public senderFirstName: string;
  public senderNickname: string;
  public senderInitials: string;
  public senderColor: string;
  public senderPictureUrl: string;
  public studioTitle: string;
  public studioColor: string;

  constructor(id: string, userId: string, message: string, senderUserId: string, senderFirstName: string, senderNickname: string, senderInitials: string, senderColor: string, senderPictureUrl: string, studioTitle: string, studioColor: string) {
    super(id, userId, message);
    this.type = 'StudioDeletionNotification';
    this.senderUserId = senderUserId;
    this.senderFirstName = senderFirstName;
    this.senderNickname = senderNickname;
    this.senderInitials = senderInitials;
    this.senderColor = senderColor;
    this.senderPictureUrl = senderPictureUrl;
    this.studioTitle = studioTitle;
    this.studioColor = studioColor;
  }
}

export abstract class InvitationAcknowledgementNotification extends AcknowledgementNotification {
  public invitationId: string;

  constructor(id: string, userId: string, message: string, invitationId: string) {
    super(id, userId, message);
    this.type = 'InvitationAcknowledgementNotification';
    this.invitationId = invitationId;
  }
}

export abstract class InvitationSelectionNotification extends SelectionNotification {
  public invitationId: string;

  constructor(id: string, userId: string, message: string, actionsPossible: string[], invitationId: string) {
    super(id, userId, message, actionsPossible);
    this.type = 'InvitationSelectionNotification';
    this.invitationId = invitationId;
  }
}

@registerDomainType('StudioInvitationNotFoundNotification')
export class StudioInvitationNotFoundNotification extends InvitationAcknowledgementNotification {

  constructor(id: string, userId: string, message: string, invitationId: string) {
    super(id, userId, message, invitationId);
    this.type = 'StudioInvitationNotFoundNotification';
  }
}

@registerDomainType('StudioInvitationRepliedNotification')
export class StudioInvitationRepliedNotification extends InvitationAcknowledgementNotification {
  public senderUserId: string;
  public senderFirstName: string;
  public senderNickname: string;
  public senderInitials: string;
  public senderColor: string;
  public senderPictureUrl: string;
  public studioId: string;
  public studioTitle: string;
  public studioColor: string;

  constructor(id: string, userId: string, message: string, invitationId: string, senderUserId: string, senderFirstName: string, senderNickname: string, senderInitials: string, senderColor: string, senderPictureUrl: string, studioId: string, studioTitle: string, studioColor: string) {
    super(id, userId, message, invitationId);
    this.type = 'StudioInvitationRepliedNotification';
    this.senderUserId = senderUserId;
    this.senderFirstName = senderFirstName;
    this.senderNickname = senderNickname;
    this.senderInitials = senderInitials;
    this.senderColor = senderColor;
    this.senderPictureUrl = senderPictureUrl;
    this.studioId = studioId;
    this.studioTitle = studioTitle;
    this.studioColor = studioColor;
  }
}

@registerDomainType('StudioInvitationExpiredNotification')
export class StudioInvitationExpiredNotification extends InvitationAcknowledgementNotification {
  public senderUserId: string;
  public senderFirstName: string;
  public senderNickname: string;
  public senderInitials: string;
  public senderColor: string;
  public senderPictureUrl: string;
  public studioId: string;
  public studioTitle: string;
  public studioColor: string;

  constructor(id: string, userId: string, message: string, invitationId: string, senderUserId: string, senderFirstName: string, senderNickname: string, senderInitials: string, senderColor: string, senderPictureUrl: string, studioId: string, studioTitle: string, studioColor: string) {
    super(id, userId, message, invitationId);
    this.type = 'StudioInvitationExpiredNotification';
    this.senderUserId = senderUserId;
    this.senderFirstName = senderFirstName;
    this.senderNickname = senderNickname;
    this.senderInitials = senderInitials;
    this.senderColor = senderColor;
    this.senderPictureUrl = senderPictureUrl;
    this.studioId = studioId;
    this.studioTitle = studioTitle;
    this.studioColor = studioColor;
  }
}

@registerDomainType('StudioInvitationAlreadyInStudioNotification')
export class StudioInvitationAlreadyInStudioNotification extends InvitationAcknowledgementNotification {
  public senderUserId: string;
  public senderFirstName: string;
  public senderNickname: string;
  public senderInitials: string;
  public senderColor: string;
  public senderPictureUrl: string;
  public studioId: string;
  public studioTitle: string;
  public studioColor: string;

  constructor(id: string, userId: string, message: string, invitationId: string, senderUserId: string, senderFirstName: string, senderNickname: string, senderInitials: string, senderColor: string, senderPictureUrl: string, studioId: string, studioTitle: string, studioColor: string) {
    super(id, userId, message, invitationId);
    this.type = 'StudioInvitationAlreadyInStudioNotification';
    this.senderUserId = senderUserId;
    this.senderFirstName = senderFirstName;
    this.senderNickname = senderNickname;
    this.senderInitials = senderInitials;
    this.senderColor = senderColor;
    this.senderPictureUrl = senderPictureUrl;
    this.studioId = studioId;
    this.studioTitle = studioTitle;
    this.studioColor = studioColor;
  }
}

@registerDomainType('StudioInvitationNotification')
export class StudioInvitationNotification extends InvitationSelectionNotification {
  public senderUserId: string;
  public senderFirstName: string;
  public senderNickname: string;
  public senderInitials: string;
  public senderColor: string;
  public senderPictureUrl: string;
  public studioId: string;
  public studioTitle: string;
  public studioColor: string;

  constructor(id: string, userId: string, message: string, invitationId: string, senderUserId: string, senderFirstName: string, senderNickname: string, senderInitials: string, senderColor: string, senderPictureUrl: string, studioId: string, studioTitle: string, studioColor: string) {
    super(id, userId, message, ['accept', 'decline', 'reportNonLegit'], invitationId);
    this.type = 'StudioInvitationNotification';
    this.senderUserId = senderUserId;
    this.senderFirstName = senderFirstName;
    this.senderNickname = senderNickname;
    this.senderInitials = senderInitials;
    this.senderColor = senderColor;
    this.senderPictureUrl = senderPictureUrl;
    this.studioId = studioId;
    this.studioTitle = studioTitle;
    this.studioColor = studioColor;
  }
}

@registerDomainType('StudioInvitationAcceptedNotification')
export class StudioInvitationAcceptedNotification extends InvitationSelectionNotification {
  public senderUserId: string;
  public senderFirstName: string;
  public senderNickname: string;
  public senderInitials: string;
  public senderColor: string;
  public senderPictureUrl: string;
  public studioId: string;
  public studioTitle: string;
  public studioColor: string;

  constructor(id: string, userId: string, message: string, invitationId: string, senderUserId: string, senderFirstName: string, senderNickname: string, senderInitials: string, senderColor: string, senderPictureUrl: string, studioId: string, studioTitle: string, studioColor: string) {
    super(id, userId, message, ['admit', 'refuse'], invitationId);
    this.type = 'StudioInvitationAcceptedNotification';
    this.senderUserId = senderUserId;
    this.senderFirstName = senderFirstName;
    this.senderNickname = senderNickname;
    this.senderInitials = senderInitials;
    this.senderColor = senderColor;
    this.senderPictureUrl = senderPictureUrl;
    this.studioId = studioId;
    this.studioTitle = studioTitle;
    this.studioColor = studioColor;
  }
}

@registerDomainType('StudioInvitationDeclinedNotification')
export class StudioInvitationDeclinedNotification extends InvitationAcknowledgementNotification {
  public senderUserId: string;
  public senderFirstName: string;
  public senderNickname: string;
  public senderInitials: string;
  public senderColor: string;
  public senderPictureUrl: string;
  public studioId: string;
  public studioTitle: string;
  public studioColor: string;

  constructor(id: string, userId: string, message: string, invitationId: string, senderUserId: string, senderFirstName: string, senderNickname: string, senderInitials: string, senderColor: string, senderPictureUrl: string, studioId: string, studioTitle: string, studioColor: string) {
    super(id, userId, message, invitationId);
    this.type = 'StudioInvitationDeclinedNotification';
    this.senderUserId = senderUserId;
    this.senderFirstName = senderFirstName;
    this.senderNickname = senderNickname;
    this.senderInitials = senderInitials;
    this.senderColor = senderColor;
    this.senderPictureUrl = senderPictureUrl;
    this.studioId = studioId;
    this.studioTitle = studioTitle;
    this.studioColor = studioColor;
  }
}
@registerDomainType('StudioInvitationAdmittanceAcceptedNotification')
export class StudioInvitationAdmittanceAcceptedNotification extends InvitationAcknowledgementNotification {
  public senderUserId: string;
  public senderFirstName: string;
  public senderNickname: string;
  public senderInitials: string;
  public senderColor: string;
  public senderPictureUrl: string;
  public studioId: string;
  public studioTitle: string;
  public studioColor: string;

  constructor(id: string, userId: string, message: string, invitationId: string, senderUserId: string, senderFirstName: string, senderNickname: string, senderInitials: string, senderColor: string, senderPictureUrl: string, studioId: string, studioTitle: string, studioColor: string) {
    super(id, userId, message, invitationId);
    this.type = 'StudioInvitationAdmittanceAcceptedNotification';
    this.senderUserId = senderUserId;
    this.senderFirstName = senderFirstName;
    this.senderNickname = senderNickname;
    this.senderInitials = senderInitials;
    this.senderColor = senderColor;
    this.senderPictureUrl = senderPictureUrl;
    this.studioId = studioId;
    this.studioTitle = studioTitle;
    this.studioColor = studioColor;
  }
}

@registerDomainType('StudioInvitationAdmittanceDeniedNotification')
export class StudioInvitationAdmittanceDeniedNotification extends InvitationAcknowledgementNotification {
  public senderUserId: string;
  public senderFirstName: string;
  public senderNickname: string;
  public senderInitials: string;
  public senderColor: string;
  public senderPictureUrl: string;
  public studioId: string;
  public studioTitle: string;
  public studioColor: string;

  constructor(id: string, userId: string, message: string, invitationId: string, senderUserId: string, senderFirstName: string, senderNickname: string, senderInitials: string, senderColor: string, senderPictureUrl: string, studioId: string, studioTitle: string, studioColor: string) {
    super(id, userId, message, invitationId);
    this.type = 'StudioInvitationAdmittanceDeniedNotification';
    this.senderUserId = senderUserId;
    this.senderFirstName = senderFirstName;
    this.senderNickname = senderNickname;
    this.senderInitials = senderInitials;
    this.senderColor = senderColor;
    this.senderPictureUrl = senderPictureUrl;
    this.studioId = studioId;
    this.studioTitle = studioTitle;
    this.studioColor = studioColor;
  }
}

export enum EnumTeammateDepartureReason {
  left = 'left',
  removed = 'removed',
}

@registerDomainType('StudioDeparture')
export class StudioDeparture {
  public type: string;
  public creationInstant: Date; // the milliseconds elapsed between 1 January 1970 00:00:00 UTC
  public reason: EnumTeammateDepartureReason;
  public message: string;
  public removerUserId: string | null;

  constructor(creationInstant: Date, reason: EnumTeammateDepartureReason = EnumTeammateDepartureReason.left, message: string = '', removerUserId: string | null = null) {
    this.type = 'StudioDeparture';
    this.creationInstant = creationInstant;
    this.reason = reason;
    this.message = message;
    this.removerUserId = removerUserId;
  }
}

@registerDomainType('Teammate')
export class Teammate {
  public type: string;
  public userId: string; // correspond to userId
  public creationInstant: Date;
  public tags: Map<string, Tag>;
  public departure: StudioDeparture | null;

  constructor(userId: string, creationInstant: Date, tags: Tag[] = []) {
    this.type = 'Teammate';
    this.userId = userId;
    this.creationInstant = creationInstant;
    this.tags = new Map<string, Tag>();
    tags.forEach((tag) => this.tags.set(tag.id, tag));
    this.departure = null;
  }
}

export abstract class Action {
  public type: string;
  public id: string;
  public creationInstant: Date;
  public syncInstant: Date;
  public userId: string;
  public isAutomated: boolean;

  constructor(id: string, creationInstant: Date, userId: string, ) {
    this.type = 'Action';
    this.id = id;
    this.creationInstant = creationInstant;
    this.syncInstant = creationInstant;
    this.userId = userId;
    this.isAutomated = false; //impossible for user to create an automated action. they can only come from the server
  }
}

@registerDomainType('StudioCreated')
export class StudioCreated extends Action {
  public initialTags: Tag[];
  public initialTitle: string;
  public initialPurpose: string;
  public initialIsSolo: boolean;

  constructor(id: string, creationInstant: Date, userId: string, initialTags: Tag[], initialTitle: string, initialPurpose: string, initialIsSolo: boolean) {
    super(id, creationInstant, userId);
    this.type = 'StudioCreated';
    this.initialTags = initialTags;
    this.initialTitle = initialTitle;
    this.initialPurpose = initialPurpose;
    this.initialIsSolo = initialIsSolo;
  }
}

@registerDomainType('StudioOpened')
export class StudioOpened extends Action {
  public reason: string;

  constructor(id: string, creationInstant: Date, userId: string, reason: string = '') {
    super(id, creationInstant, userId);
    this.type = 'StudioOpened';
    this.reason = reason;
  }
}

@registerDomainType('StudioClosed')
export class StudioClosed extends Action {
  public reason: string;

  constructor(id: string, creationInstant: Date, userId: string, reason: string = '') {
    super(id, creationInstant, userId);
    this.type = 'StudioClosed';
    this.reason = reason;
  }
}

export abstract class ActionChange extends Action {
  public oldValue: string;
  public newValue: string;

  constructor(id: string, creationInstant: Date, userId: string, oldValue: string, newValue: string) {
    super(id, creationInstant, userId);
    this.type = 'ActionChange';
    this.oldValue = oldValue;
    this.newValue = newValue;
  }
}

@registerDomainType('StudioTitleChanged')
export class StudioTitleChanged extends ActionChange {

  constructor(id: string, creationInstant: Date, userId: string, oldTitle: string, newTitle: string) {
    super(id, creationInstant, userId, oldTitle, newTitle);
    this.type = 'StudioTitleChanged';
  }
}

@registerDomainType('StudioPurposeChanged')
export class StudioPurposeChanged extends ActionChange {

  constructor(id: string, creationInstant: Date, userId: string, oldPurpose: string, newPurpose: string) {
    super(id, creationInstant, userId, oldPurpose === '' ? '""' : oldPurpose, newPurpose === '' ? '""' : newPurpose);
    this.type = 'StudioPurposeChanged';
  }
}

@registerDomainType('StudioSoloStatusChanged')
export class StudioSoloStatusChanged extends ActionChange {

  constructor(id: string, creationInstant: Date, userId: string, isSolo: boolean) {
    super(id, creationInstant, userId, isSolo ? 'off' : 'on', isSolo ? 'on' : 'off');
    this.type = 'StudioSoloStatusChanged';
  }
}

@registerDomainType('StudioInvitationCreated')
export class StudioInvitationCreated extends Action {
  public invitationId: string;
  public expirationInstant: Date;
  constructor(id: string, creationInstant: Date, userId: string, invitationId: string) {
    super(id, creationInstant, userId);
    this.type = 'StudioInvitationCreated';
    this.invitationId = invitationId;
    this.expirationInstant = new Date(DateHelper.getTimestampByAddingDaysAfterTimestamp(30, creationInstant.getTime()));
  }
}

@registerDomainType('StudioTeammateInvited')
export class StudioTeammateInvited extends Action {
  public inviteeUserId: string;
  constructor(id: string, creationInstant: Date, userId: string, inviteeUserId: string) {
    super(id, creationInstant, userId);
    this.type = 'StudioTeammateInvited';
    this.inviteeUserId = inviteeUserId;
  }
}

@registerDomainType('StudioTeammateJoined')
export class StudioTeammateJoined extends Action {
  constructor(id: string, creationInstant: Date, userId: string) {
    super(id, creationInstant, userId);
    this.type = 'StudioTeammateJoined';
  }
}

@registerDomainType('StudioTeammateLeft')
export class StudioTeammateLeft extends Action {
  public reason: string;

  constructor(id: string, creationInstant: Date, userId: string, reason: string = '') {
    super(id, creationInstant, userId);
    this.type = 'StudioTeammateLeft';
    this.reason = reason;
  }
}

@registerDomainType('StudioTeammateRemoved')
export class StudioTeammateRemoved extends Action {
  public reason: string;
  public teammateUserId: string;

  constructor(id: string, creationInstant: Date, userId: string, teammateUserId: string, reason: string = '') {
    super(id, creationInstant, userId);
    this.type = 'StudioTeammateRemoved';
    this.teammateUserId = teammateUserId;
    this.reason = reason;
  }
}

export enum EnumUrgency {
  medium = 'medium',
  high = 'high',
  low = 'low',
}

export enum EnumImportance {
  medium = 'medium',
  high = 'high',
  low = 'low',
}

export enum EnumEffort {
  xSmall = 'xSmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  xLarge = 'xLarge',
}

@registerDomainType('ToDoCreated')
export class ToDoCreated extends Action {

  constructor(id: string, creationInstant: Date, userId: string) {
    super(id, creationInstant, userId);
    this.type = 'ToDoCreated';
  }
}

export abstract class ActionWithMessage extends Action {
  public messageBody: string;
  public messageTargetUserIds: string[];

  constructor(id: string, creationInstant: Date, userId: string, messageBody: string, messageTargetUserIds: string[]) {
    super(id, creationInstant, userId);
    this.type = 'Message';
    this.messageBody = messageBody;
    this.messageTargetUserIds = messageTargetUserIds;
  }
}

@registerDomainType('Messaged')
export class Messaged extends ActionWithMessage {

  constructor(id: string, creationInstant: Date, userId: string, messageBody: string, messageTargetUserIds: string[]) {
    super(id, creationInstant, userId, messageBody, messageTargetUserIds);
    this.type = 'Messaged';
    this.messageBody = messageBody;
    this.messageTargetUserIds = messageTargetUserIds;
  }
}

@registerDomainType('ToDoCanceled')
export class ToDoCanceled extends ActionWithMessage {
  constructor(id: string, creationInstant: Date, userId: string, messageBody: string, messageTargetUserIds: string[]) {
    super(id, creationInstant, userId, messageBody, messageTargetUserIds);
    this.type = 'ToDoCanceled';
  }
}

@registerDomainType('ToDoRestored')
export class ToDoRestored extends ActionWithMessage {

  constructor(id: string, creationInstant: Date, userId: string, messageBody: string, messageTargetUserIds: string[]) {
    super(id, creationInstant, userId, messageBody, messageTargetUserIds);
    this.type = 'ToDoRestored';
  }
}

export abstract class Involvement extends ActionWithMessage {
  public associatedProgressId: string | null;

  constructor(id: string, creationInstant: Date, userId: string, messageBody: string, messageTargetUserIds: string[]) {
    super(id, creationInstant, userId, messageBody, messageTargetUserIds);
    this.type = 'Involvement';
    this.associatedProgressId = null;
  }
}

@registerDomainType('NoInvolvement')
export class NoInvolvement extends Involvement {
  constructor(userId: string, creationInstant: Date = new Date()) {
    super('00000000-0000-0000-0000-000000000000', creationInstant, userId, '', []);
    this.type = 'NoInvolvement';
  }
}

@registerDomainType('Postponed')
export class Postponed extends Involvement {
  public postponementInstant: Date;

  constructor(id: string, creationInstant: Date, userId: string, messageBody: string, messageTargetUserIds: string[], postponementInstant: Date) {
    super(id, creationInstant, userId, messageBody, messageTargetUserIds);
    this.type = 'Postponed';
    this.postponementInstant = postponementInstant;
  }
}

@registerDomainType('Committed')
export class Committed extends Involvement {
  public commitmentInstant: Date;

  constructor(id: string, creationInstant: Date, userId: string, messageBody: string, messageTargetUserIds: string[], commitmentInstant: Date, progressId: string | null = null) {
    super(id, creationInstant, userId, messageBody, messageTargetUserIds);
    this.type = 'Committed';
    this.commitmentInstant = commitmentInstant;
  }
}

@registerDomainType('Completed')
export class Completed extends Involvement {

  constructor(id: string, creationInstant: Date, userId: string, messageBody: string, messageTargetUserIds: string[], progressId: string | null = null) {
    super(id, creationInstant, userId, messageBody, messageTargetUserIds);
    this.type = 'Completed';
    this.associatedProgressId = progressId;
  }
}

@registerDomainType('Delegated')
export class Delegated extends Involvement {

  constructor(id: string, creationInstant: Date, userId: string, messageBody: string, messageTargetUserIds: string[]) {
    super(id, creationInstant, userId, messageBody, messageTargetUserIds);
    this.type = 'Delegated';
  }
}

@registerDomainType('Followed')
export class Followed extends Involvement {

  constructor(id: string, creationInstant: Date, userId: string, messageBody: string, messageTargetUserIds: string[]) {
    super(id, creationInstant, userId, messageBody, messageTargetUserIds);
    this.type = 'Followed';
  }
}

@registerDomainType('Withdrawn')
export class Withdrawn extends Involvement {

  constructor(id: string, creationInstant: Date, userId: string, messageBody: string, messageTargetUserIds: string[]) {
    super(id, creationInstant, userId, messageBody, messageTargetUserIds);
    this.type = 'Withdrawn';
  }
}

@registerDomainType('ProgressStep')
export class ProgressStep {
  public type: string;
  public id: string;
  public title: string;

  constructor(id: string, title: string) {
    this.type = 'ProgressStep';
    this.id = id;
    this.title = title;
  }
}

@registerDomainType('Progressed')
export class Progressed extends Action {
  public progressInstant: Date;
  public progressSteps: ProgressStep[];
  public triggerActionId: string | null;

  constructor(id: string, creationInstant: Date, userId: string, progressInstant: Date, progressSteps: ProgressStep[] = [], triggerActionId: string | null = null) {
    super(id, creationInstant, userId);
    this.type = 'Progressed';
    this.progressInstant = progressInstant;
    this.progressSteps = progressSteps;
    this.triggerActionId = triggerActionId;
  }
}

@registerDomainType('ToDoOwnerChanged')
export class ToDoOwnerChanged extends ActionChange {
  constructor(id: string, creationInstant: Date, userId: string, oldOwnerUserId: string, newOwnerUserId: string) {
    super(id, creationInstant, userId, oldOwnerUserId, newOwnerUserId);
    this.type = 'ToDoOwnerChanged';
  }
}

@registerDomainType('ToDoTitleChanged')
export class ToDoTitleChanged extends ActionChange {
  constructor(id: string, creationInstant: Date, userId: string, oldTitle: string, newTitle: string) {
    super(id, creationInstant, userId, oldTitle, newTitle);
    this.type = 'ToDoTitleChanged';
  }
}

@registerDomainType('ToDoDescriptionChanged')
export class ToDoDescriptionChanged extends ActionChange {
  constructor(id: string, creationInstant: Date, userId: string, oldDescription: string, newDescription: string) {
    super(id, creationInstant, userId, oldDescription === '' ? '""' : oldDescription, newDescription === '' ? '""' : newDescription);
    this.type = 'ToDoDescriptionChanged';
  }
}

@registerDomainType('ToDoUrgencyChanged')
export class ToDoUrgencyChanged extends ActionChange {
  constructor(id: string, creationInstant: Date, userId: string, oldUrgency: string, newUrgency: string) {
    super(id, creationInstant, userId, oldUrgency, newUrgency);
    this.type = 'ToDoUrgencyChanged';
  }
}

@registerDomainType('ToDoImportanceChanged')
export class ToDoImportanceChanged extends ActionChange {
  constructor(id: string, creationInstant: Date, userId: string, oldImportance: string, newImportance: string) {
    super(id, creationInstant, userId, oldImportance, newImportance);
    this.type = 'ToDoImportanceChanged';
  }
}

@registerDomainType('ToDoEffortChanged')
export class ToDoEffortChanged extends ActionChange {
  constructor(id: string, creationInstant: Date, userId: string, oldEffort: string, newEffort: string) {
    super(id, creationInstant, userId, oldEffort, newEffort);
    this.type = 'ToDoEffortChanged';
  }
}

@registerDomainType('ToDoDueInstantChanged')
export class ToDoDueInstantChanged extends ActionChange {
  constructor(id: string, creationInstant: Date, userId: string, oldDueInstant: Date | null, newDueInstant: Date | null) {
    super(id, creationInstant, userId, oldDueInstant === null ? '' : oldDueInstant.toISOString(), newDueInstant === null ? '' : newDueInstant.toISOString());
    this.type = 'ToDoDueInstantChanged';
  }
}
@registerDomainType('ToDoRecurrenceChanged')
export class ToDoRecurrenceChanged extends ActionChange {
  constructor(id: string, creationInstant: Date, userId: string, oldRecurrence: string, newRecurrence: string) {
    super(id, creationInstant, userId, oldRecurrence, newRecurrence);
    this.type = 'ToDoRecurrenceChanged';
  }
}

export enum EnumPeriod {
  none = 'none',
  morning = 'morning',
  afternoon = 'afternoon',
  evening = 'evening',
  overnight = 'overnight',
}

@registerDomainType('ToDoUserState')
export class ToDoUserState {
  public type: string;
  public userId: string;
  public lastMessageSeenInstant: Date;
  public isStarred: boolean;
  public lastInvolvement: Involvement | null;
  public lastProgress: Progressed | null;

  constructor(userId: string, lastMessageSeenInstant: Date = new Date()) {
    this.type = 'ToDoUserState';
    this.userId = userId;
    this.lastMessageSeenInstant = lastMessageSeenInstant;
    this.isStarred = false;
    this.lastInvolvement = null;
    this.lastProgress = null;
  }
}

@registerDomainType('ExternalId')
export class ExternalId {
  public type: string;
  public id: string;
  public provider: string;

  constructor(id: string, provider: string) {
    this.type = 'ExternalId';
    this.id = id;
    this.provider = provider;
  }
}

export enum EnumPreRequisiteCondition {
  started = 'started',
  completed = 'completed',
}

@registerDomainType('PreRequisite')
export class PreRequisite {
  public type: string;
  public id: string;
  public publisherToDoId: string;
  public condition: EnumPreRequisiteCondition;
  public isMet: boolean;

  constructor(id: string, publisherToDoId: string, condition: EnumPreRequisiteCondition, isMet: boolean) {
    this.type = 'PreRequisite';
    this.id = id;
    this.publisherToDoId = publisherToDoId;
    this.condition = condition;
    this.isMet = isMet;
  }
}

@registerDomainType('Step')
export class Step {
  public type: string;
  public id: string;
  public title: string;
  public creatorUserId: string;
  public deletionUserId: string | null;
  public creationInstant: Date;
  public deletionInstant: Date | null;
  public progress: Progressed | null;

  constructor(id: string, creationInstant: Date, creatorUserId: string, title: string, progress: Progressed | null = null) {
    this.type = 'Step';
    this.id = id;
    this.creationInstant = creationInstant;
    this.creatorUserId = creatorUserId;
    this.title = title;
    this.progress = progress;
  }
}

export abstract class ToDo {
  public type: string;
  public id: string;
  public etag: string;
  public creationInstant: Date;
  public creatorUserId: string;
  public ownerUserId: string;
  public studioId: string;
  public startedInstant: Date | null;
  public completionInstant: Date | null;
  public cancellationInstant: Date | null;
  public get isWaiting(): boolean { return this.isDone || this.isCanceled ? false : (this.startedInstant === null); }
  public get isInProgress(): boolean { return this.isDone || this.isCanceled ? false : (this.startedInstant !== null); }
  public get isDone(): boolean { return this.completionInstant === null ? false : true; }
  public get isCanceled(): boolean { return this.cancellationInstant === null ? false : true; }
  public tags: Map<string, Tag>;
  public steps:  Map<string, Step>;
  public title: string;
  public description: string;
  public effort: EnumEffort;
  public urgency: EnumUrgency;
  public importance: EnumImportance;
  public dueInstant: Date | null;
  public recurrence: string;
  public userStates: Map<string, ToDoUserState>;
  public actions: Map<string, Action>;
  public isNew(userId: string): boolean {
    this.actions.forEach((action) => {
      if (action.userId === userId) { return false; };
    });
    return true;
  }
  public externalId: ExternalId | null = null;
  public preRequisites: Map<string, PreRequisite>;

  public derivedLatestMessage: Involvement | ActionWithMessage;
  public derivedChangeCount : number;

  constructor(id: string, creationInstant: Date, userId: string, studioId: string, tags: Tag[], title: string, description: string, ownerUserId: string) {
    this.type = 'ToDo';
    this.id = id;
    this.studioId = studioId;
    this.creationInstant = creationInstant;
    this.creatorUserId = userId;
    this.ownerUserId = ownerUserId;
    this.startedInstant = null;
    this.completionInstant = null;
    this.cancellationInstant = null;
    this.tags = new Map<string, Tag>();
    tags.forEach((tag) => this.tags.set(tag.id, tag));
    this.steps = new Map<string, Step>();
    this.title = title;
    this.description = description;
    this.effort = EnumEffort.xSmall;
    this.urgency = EnumUrgency.medium;
    this.importance = EnumImportance.medium;
    this.dueInstant = null;
    this.recurrence = RecurrenceHelper.noRecurrence;
    this.userStates = new Map<string, ToDoUserState>();
    this.userStates.set(userId, new ToDoUserState(userId, creationInstant));
    this.actions = new Map<string, Action>();
    const action = new ToDoCreated(id, creationInstant, userId);
    this.actions.set(action.id, action);
    this.preRequisites = new Map<string, PreRequisite>();

    this.derivedLatestMessage = new NoInvolvement(userId, creationInstant);
    this.derivedChangeCount = 1;
  }
}

@registerDomainType('Task')
export class Task extends ToDo {
  constructor(id: string, creationInstant: Date, userId: string, studioId: string, tags: Tag[], title: string, description: string, ownerUserId: string) {
    super(id, creationInstant, userId, studioId, tags, title, description, ownerUserId);
    this.type = 'Task';
  }
}

@registerDomainType('StudioUserState')
export class StudioUserState {
  public type: string;
  public userId: string;

  constructor(userId: string) {
    this.type = 'StudioUserState';
    this.userId = userId;
  }
}
export enum EnumToDoTemplateItemToDoType {
  Task = 'task',
}

@registerDomainType('ToDoTemplateItem') // no type as this is used within an array
export class ToDoTemplateItem {
  public id: string;
  public title: string;
  public description: string;
  public toDoType: EnumToDoTemplateItemToDoType;

  constructor(id: string, title: string, description: string = '', toDoType: EnumToDoTemplateItemToDoType = EnumToDoTemplateItemToDoType.Task) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.toDoType = toDoType;
  }
}

@registerDomainType('ToDoTemplate')
export class ToDoTemplate {
  public id: string;
  public type: string;
  public lastUpdateUserId: string;
  public lastUpdateInstant: Date;
  public name: string;

  public items: ToDoTemplateItem[] = [];

  constructor(id: string, lastUpdateInstant: Date, lastUpdateUserId: string, name: string) {
    this.type = 'ToDoTemplate';
    this.id = id;
    this.lastUpdateUserId = lastUpdateUserId;
    this.lastUpdateInstant = lastUpdateInstant;
    this.name = name;
  }
}

export abstract class Studio {
  public type: string;
  public id: string;
  public etag: string;
  public creationInstant: Date;
  public creatorUserId: string;
  public isSolo: boolean;
  public closedInstant: Date | null;
  public closeUserId: string;
  public get isOpen(): boolean { return (this.closedInstant === null); }
  public get isClosed(): boolean { return (this.closedInstant !== null); }

  public tags: Map<string, Tag>;
  public toDoTemplates: Map<string, ToDoTemplate>;
  
  public title: string;
  public purpose: string;
  public color: string;
  public featureFlags: Map<string, string>;
  public teammates: Map<string, Teammate>;
  public userStates: Map<string, StudioUserState>;
  public actions: Map<string, Action>;

  public derivedChangeCount: number;

  constructor(id: string, creationInstant: Date, userId: string, tags: Tag[], title: string, purpose: string, isSolo: boolean) {
    this.type = 'Studio';
    this.id = id;
    this.creationInstant = creationInstant;
    this.creatorUserId = userId;
    this.isSolo = isSolo;
    this.closedInstant = null;
    this.closeUserId = UUID.NIL.toString();
    this.tags = new Map<string, Tag>();
    tags.forEach((tag) => this.tags.set(tag.id, tag));
    this.title = title;
    this.color = StringHelper.toPastelColor(this.title);
    this.purpose = purpose;
    this.featureFlags = new Map<string, string>();
    this.teammates = new Map<string, Teammate>();
    this.teammates.set(userId, new Teammate(userId, creationInstant));
    this.userStates = new Map<string, StudioUserState>();
    this.userStates.set(userId, new StudioUserState(userId));
    this.actions = new Map<string, Action>();
    this.toDoTemplates = new Map<string, ToDoTemplate>();
    const action = new StudioCreated(id, creationInstant, userId, tags, title, purpose, isSolo);
    this.actions.set(action.id, action);
    this.derivedChangeCount = 1;
  }
}

@registerDomainType('TeamStudio')
export class TeamStudio extends Studio {

  constructor(id: string, creationInstant: Date, userId: string, tags: Tag[], title: string, purpose: string = '', isSolo: boolean = false) {
    super(id, creationInstant, userId, tags, title, purpose, isSolo);
    this.type = 'TeamStudio';
  }
}

@registerDomainType('OrgStudio')
export class OrgStudio extends Studio {
  public organizationId: string;

  constructor(id: string, organizationId: string, creationInstant: Date, userId: string, tags: Tag[], title: string, purpose: string = '') {
    super(id, creationInstant, userId, tags, title, purpose, false);
    this.type = 'OrgStudio';
    this.organizationId = organizationId;
  }
}


class TipData {
  public kind: string;
  public category: string;
  public i18nPrompt: string;
  public priority: number;
}
const TipsDataMap = new Map<string, TipData>();
(TipsDataArray as Array<TipData>).forEach((item) => {
  TipsDataMap.set(item.kind, item);
});

@registerDomainType('TipBase')
export abstract class TipBase {
  public id: string;
  public type: string;
  public kind: string;
  public category: string;
  public i18nPrompt: string;
  public priority: number;

  constructor(kind: string) {
    this.id = UUID.genV1().toString();
    this.type = 'Tip';
    this.kind = kind;

    const tipData = TipsDataMap.get(kind);
    if (tipData !== undefined) {
      this.category = tipData.category;
      this.i18nPrompt = tipData.i18nPrompt;
      this.priority = tipData.priority;
    } else {
      throw new Error('From constructor in Tip: kind ' + kind + ' does not exist');
    }
  }
}

@registerDomainType('ToDoTip')
export class ToDoTip extends TipBase {
  public studio: Studio;
  public toDo: ToDo;
  public userInvolvement: Involvement;
  public teammateMessage: Involvement | ActionWithMessage | null;
  public get naturalKey(): string { return (this.toDo.id + ((this.userInvolvement !== null) ? this.userInvolvement.id : '') + this.kind); }

  constructor(kind: string, studio: Studio, toDo: ToDo, userInvolvement: Involvement, teammateMessage: Involvement | ActionWithMessage | null = null) {
    super(kind);
    this.studio = studio;
    this.toDo = toDo;
    this.userInvolvement = userInvolvement;
    this.teammateMessage = teammateMessage;
  }
}

@registerDomainType('Tip')
export class Tip extends TipBase {
  public toDoTips: ToDoTip[];

  constructor(kind: string, toDoTips: ToDoTip[]) {
    super(kind);
    this.type = 'Tip';
    this.toDoTips = toDoTips;
  }
}

