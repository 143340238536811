import Vue from 'vue';
import Vuex from 'vuex';
import AuthenticationModule from '@/modules/AuthenticationModule';
import ConfigModule from '@/modules/ConfigModule';
import AppModule from '@/modules/AppModule';
import UserModule from '@/modules/UserModule';
import StudioModule from '@/modules/StudioModule';
import NotificationModule from '@/modules/NotificationModule';
import TipModule from '@/modules/TipModule';
import VuexMagic from './vuexMagic';

let isStrict = process.env.NODE_ENV !== 'production';
// eslint-disable-next-line prefer-const
let environmentName = process.env.NODE_ENV === 'production' ? 'staging' : 'dev';
// eslint-disable-next-line prefer-const
let ringName = process.env.NODE_ENV === 'production' ? 'user' : 'user';
if (environmentName === 'prod' && ringName === 'user' && localStorage['To-Do.Studio/App/Debug_VueJS'] !== 'true') {
  isStrict = false;
} else {
  isStrict = true;
}

Vue.use(Vuex);

export interface IRootType {
  authentication: AuthenticationModule;
  app: AppModule;
  config: ConfigModule;
  user: UserModule;
  studio: StudioModule;
  notification: NotificationModule;
  tip: TipModule;
}

export const VueXMagicInstance = new VuexMagic<IRootType>();

const store = new Vuex.Store<IRootType>({
  strict: isStrict,
  plugins: [VueXMagicInstance.plugin],
});

export default store;
